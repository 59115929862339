@import "src/styles/helpers";

.screen {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;

  &_wide {
    margin: 64px 24px;

    @include media(portrait) {
      maring: 104px 64px;
    }

    @include media(landscape) {
      maring: 120px 72px;
    }

    @include media(desktopAll) {
      margin-left: 0;
      maring-right: 0;
    }
  }

  &_tablet {
    padding: 4.3%;
    width: 100%;
    background-image: url('/media/tablet-frame.svg');
  }


  &_slider {
    @media screen and (max-width: 1279px) {
      --side-margins: 24px;
      --negative-side-margins: -24px;

      padding: 0;

      margin-left: var(--negative-side-margins) !important;
      margin-right: var(--negative-side-margins) !important;
      width: calc(100% + var(--side-margins) * 2) !important;

      background-image: none;

      .screen__content {
        background-color: transparent;
      }

      .slider {
        background-color: transparent;
        aspect-ratio: auto;

        img {
          border-radius: 20px;
          padding: 4%;
        }
      }

      :global {
        .splide__slide {
          height: 100%;
          aspect-ratio: 329/239 !important;
          background-image: url('/media/tablet-frame.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100% !important;
        }
      }
    }

    @include media(portrait) {
      --side-margins: 92px;
      --negative-side-margins: -92px;
    }

    @include media(landscape) {
      --side-margins: 72px;
      --negative-side-margins: -72px;
    }
  }

  :global {
    .splide__track {
      height: 100% !important;
      overflow: hidden !important;
    }
  }

  &__content {
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: stretch;
    max-height: 100%;
    aspect-ratio: 304/212;
    background: var(--white);
    overflow: hidden;

    img {
      border-radius: 5px;
    }
  }
}


.slider {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 304/212;
  background: var(--white);

  @include media(desktopAll) {
    overflow: hidden;
  }

  img {
    width: 100%;
  }
}

.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 12px;
  padding: 0 !important;
  position: static !important;
  margin-top: 32px !important;

  @include media(tablet) {
    gap: 20px;
  }

  @include media(desktopAll) {
    display: none
  }

  &__item {
    @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
    width: 8px !important;
    height: 8px !important;
    margin: 0 !important;

    &:global {

      &.is-active {
        transform: none !important;
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }

    @include media(tablet) {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.phoneClip {
  background-image: url('/media/phone-frame.svg');
  background-size: cover;
}

.optionalImage {
  padding: 4px 5px 6px;
  width: 94px;
  height: 188px;
  background-image: url('/media/phone-frame.svg');
  background-size: cover;

  position: absolute;
  right: -5px;
  bottom: -50px;

  &_onLeft {
    right: unset;
    left: -3px;
  }

  @include media(portrait) {
    right: -43px;
    padding: 8px 9px 10px 10px;
    width: 175px;
    height: 351px;


    &_onLeft {
      right: unset;
      left: -26px;
    }
  }

  @include media(landscape) {
    right: -58px;
    padding: 12px 13px 11px 14px;
    width: 234px;
    height: 468px;

    &_onLeft {
      right: unset;
      left: -35px;
    }
  }

  @include media(desktopAll) {
    right: -63px;
    padding: 12px 15px 11px 15px;
    width: 278px;
    height: 557px;

    &_onLeft {
      right: unset;
      left: -110px;
    }
  }
}